


























import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import GoogleMapsStylesJson from '../../../../Configuration/GoogleMapsStyles.json';
import UcContextUi from '../../UcContextUi/UcContextUi.vue';

export default {
    name: 'MapImageTag',
    components: {UcContextUi},
    props: {
        context: null as ContentElementContext,
        latitude: null,
        longitude: null,
        size: {
            // https://developers.google.com/maps/documentation/maps-static/dev-guide#map-parameters
            Type: String,
            default: '500x300'
        },
        scale: {
            // https://developers.google.com/maps/documentation/maps-static/dev-guide#Imagesizes
            Type: Number,
            default: 1
        },
        zoom: {
            // https://developers.google.com/maps/documentation/maps-static/dev-guide#Zoomlevels
            Type: Number,
            default: 15
        },
        title: ''
    },
    computed: {
        src(): string
        {
            return this.buildUrl(this.latitude, this.longitude, this.size, this.scale, this.zoom);
        }
    },
    methods: {
        // https://developers.google.com/maps/documentation/maps-static/dev-guide
        buildUrl(latitude: number, longitude: number, size: string, scale: number, zoom: number): string
        {
            let url = 'https://maps.googleapis.com/maps/api/staticmap?';
            url += 'center=' + latitude + ',' + longitude;
            url += '&markers=' + this.getMarkerIcon() + '|' + latitude + ',' + longitude;
            url += '&size=' + size;
            url += '&scale=' + scale;
            url += '&zoom=' + zoom;
            url += '&key=' + this.context.appContext.pageContext.data.get('googleMapsApiKey');
            url += '&' + this.googleMapStylesToUrlArguments(GoogleMapsStylesJson);
            return url;
        },

        getMarkerIcon(): string
        {
            return 'icon:' + this.context.appContext.pageContext.baseUrl + '/assets/icons/map-pin-green.svg';
        },

        // Taken from https://github.com/eperedo/vue-static-map/pull/7/commits/c47539baf98275651ff3dbf599fa549000bead9a#diff-3528ac590de2f29d516b15f47952a0f3
        googleMapStylesToUrlArguments(mapStyleJson): string
        {
            const result = [];
            mapStyleJson.forEach(v => {
                let style = '';
                if (v.stylers) {
                    if (v.stylers.length > 0) {
                        style += `${(
                            Object.prototype.hasOwnProperty.call(v, 'featureType') ? `feature:${v.featureType}`
                                : 'feature:all'
                        )}|`;
                        style += `${(
                            Object.prototype.hasOwnProperty.call(v, 'elementType') ? `element:${v.elementType}`
                                : 'element:all'
                        )}|`;
                        v.stylers.forEach(val => {
                            const propertyname = Object.keys(val)[0];
                            const propertyval = val[propertyname].toString().replace('#', '0x');
                            style += `${propertyname}:${propertyval}|`;
                        });
                    }
                }
                result.push(`style=${encodeURIComponent(style)}`);
            });
            return result.join('&');
        }
    }
};

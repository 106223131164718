var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasBranch
    ? _c("div", { staticClass: "branchTeaser" }, [
        _c("div", { staticClass: "branchTeaser__container" }, [
          _c("div", { staticClass: "branchTeaser__title" }, [
            _vm._v(_vm._s(_vm.$t("branch.teaser.title"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "branchTeaser__inner", class: _vm.classes },
            [
              !_vm.noMap
                ? _c(
                    "div",
                    { ref: "googleMaps", staticClass: "branchTeaser__map" },
                    [
                      _c("map-image-tag", {
                        attrs: {
                          context: _vm.context,
                          size: _vm.mapSize,
                          scale: 2,
                          zoom: 10,
                          latitude: _vm.branch.latitude,
                          longitude: _vm.branch.longitude,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "teaserInfo", staticClass: "branchTeaser__info" },
                [
                  _c("current-branch", {
                    attrs: {
                      branch: _vm.branch,
                      "company-color": false,
                      "btn-link": _vm.branch.url,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button",
                          fn: function () {
                            return [_vm._t("button")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }













































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../../../AppStoreKeys';
import Btn from '../../Misc/Btn/Btn.vue';
import MapImageTag from '../../Misc/Image/MapImageTag/MapImageTag.vue';
import CurrentBranch from '../CurrentBranch/CurrentBranch.vue';

export default {
    name: 'BranchTeaser',
    components: {MapImageTag, CurrentBranch, Btn},
    props: {
        context: null as PageContext,
        isHorizontal: {
            type: Boolean,
            default: false
        },
        branch: Object,
        noMap: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            mapSize: '500x300'
        };
    },
    computed: {
        hasBranch(): boolean
        {
            return !isEmpty(this.branch);
        },
        classes(): Object
        {
            return {
                'branchTeaser__inner--horizontal': this.isHorizontal
            };
        }
    },
    methods: {
        /*
        * set map size for the google maps api. Because of the limit of 640px x 640px we set the api scale to 2.
        * 80% map size gives a good balance between size and sharpness of the image without exceeding the api limit.
        * For lower image data size reduce the size but kep in mind that the text on the image also scales.
         */
        setMapSize(): string
        {
            if (isUndefined(this.$refs.googleMaps)) {
                return;
            }

            const heightMultiplier = this.isHorizontal ? 0.8 : 0.65;
            this.mapSize = Math.round(this.$refs.googleMaps.offsetWidth * 0.8) + 'x' + Math.round(
                this.$refs.teaserInfo.offsetHeight * heightMultiplier);
        }
    },
    mounted(): void
    {
        this.$nextTick(() => this.setMapSize());
        // @todo test if too many api requests
        this.$watch(() => this.context.store.get(AppStoreKeys.BREAKPOINT), () => this.setMapSize());
    }
};
